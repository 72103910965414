.styleDatePicker{  
  display: flex;
  background: #FFFFFF;
  outline: none;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #8C66FF;
  box-sizing: border-box;
  padding-left: 20px;
  box-shadow: 0px 4px 10px rgba(137, 137, 137, 0.1);
  background-image: url(../../assets/images/icon-datePicker.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-origin: content-box;
}

.styleDatePicker:focus{
  box-shadow: 0px 2px 10px rgba(86, 99, 107, 0.2);
}

.labelStyled{
  position: absolute;
  font-size: 12px;
  top: 0;
  left: 0;
  transform: translateY(-50%) scale(0.8) !important;
  background-color: #FFFFFF;
  padding: 4px;
  color: #8C66FF;
  transition: 0.1s ease-out;
  pointer-events: none;
  white-space: nowrap;
}

.divStyled{
  position: relative;
  flex-direction: column;
}
